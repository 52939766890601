<template>
  <div>
    <template v-if="$options.components['advertisement-loading']">
      <advertisement-loading v-if="isLoading" />
    </template>
    <b-container>
      <b-row>
        <b-row>
          <b-col md="7" class="mt-5">
            <template>
              <div>
                <h6 class="number mb-3">
                  Subscription ID :
                  <span class="text">{{ $route.params.number }}</span>
                  <img
                    class="edit-icon-style edit"
                    @click="goback"
                    src="img/EditIcon.png"
                    alt
                  />
                </h6>
                <h6 class="number">{{ $route.params.name }}</h6>
              </div>
            </template>
          </b-col>
        </b-row>
        <b-col md="4">
          <b-card header-class="price-header" header="PRICE DETAILS">
            <b-row class="mb-2">
              <b-col>
                <b-card-text class="bill">Recharge Amount:</b-card-text>
              </b-col>
              <b-col>
                <b-card-text class="float-right"
                  >₹ {{ this.finalCheckoutData.data.totalPrice }}</b-card-text
                >
              </b-col>
            </b-row>

            <b-row class="mb-2">
              <b-col>
                <b-card-text class="bill">Discount:</b-card-text>
              </b-col>
              <b-col>
                <b-card-text class="float-right">₹ 0</b-card-text>
              </b-col>
            </b-row>
            <hr class="line" />
            <b-row class="mb-2">
              <b-col>
                <b-card-text class="total-amount-text"
                  >Total Amount:</b-card-text
                >
              </b-col>
              <b-col>
                <b-card-text class="float-right total-amount-color"
                  >₹ {{ this.finalCheckoutData.data.totalPrice }}</b-card-text
                >
              </b-col>
            </b-row>
          </b-card>
          <template>
            <div class="transaction-benefits">
              <b-btn
                block
                href="#"
                v-b-toggle.accordion1
                variant="secondary"
                class="d-flex justify-content-between mt-4 benefitsbtn color-transaction"
              >
                <div class="transaction-benefits-heading">
                  <b-img
                    src="img/benefits-icon.png"
                    class="invert-color"
                  ></b-img
                  >Transaction Benefits
                </div>

                <span class="when-opened">
                  <b-icon icon="chevron-down"></b-icon>
                </span>
                <span class="when-closed">
                  <b-icon icon="chevron-right"></b-icon>
                </span>
              </b-btn>
              <b-collapse id="accordion1" role="tabpanel">
                <div class="showbenefits-div">
                  <b-card
                    title="Your Cash Back"
                    class="mb-4 txn-benefits-cards your-cashback"
                  >
                    <b-card-text class="mb-0 d-flex justify-content-between">
                      Dhamaka Cashback:
                      <p>
                        &#x20B9;
                        {{
                          transactionBenefits &&
                          transactionBenefits[0] &&
                          transactionBenefits[0].value > transactionBenefits &&
                          transactionBenefits[1] &&
                          transactionBenefits[1].value
                            ? (transactionBenefits &&
                                transactionBenefits[0] &&
                                transactionBenefits[0].value) ||
                              0
                            : (transactionBenefits &&
                                transactionBenefits[1] &&
                                transactionBenefits[1].value) ||
                              0
                        }}
                      </p>
                    </b-card-text>
                    <b-card-text class="d-flex justify-content-between">
                      Promotional Cashback:
                      <p>
                        &#x20B9;
                        {{
                          transactionBenefits && transactionBenefits[2]
                            ? transactionBenefits[2].value
                            : 0
                        }}
                      </p>
                    </b-card-text>
                  </b-card>
                  <b-card
                    title="Your Referrer Cash Back"
                    class="mb-4 txn-benefits-cards referrer-cashback"
                  >
                    <b-card-text class="mb-0 d-flex justify-content-between">
                      Double Dhamaka Cashback:
                      <p>
                        &#x20B9;
                        {{
                          transactionBenefits &&
                          transactionBenefits[0] &&
                          transactionBenefits[0].value > transactionBenefits &&
                          transactionBenefits[1] &&
                          transactionBenefits[1].value
                            ? (transactionBenefits &&
                                transactionBenefits[0] &&
                                transactionBenefits[0].value) ||
                              0
                            : (transactionBenefits &&
                                transactionBenefits[1] &&
                                transactionBenefits[1].value) ||
                              0
                        }}
                      </p>
                    </b-card-text>
                    <b-card-text class="d-flex justify-content-between">
                      Referrer Cashback:
                      <p>
                        &#x20B9;
                        {{
                          transactionBenefits && transactionBenefits[3]
                            ? transactionBenefits[3].value
                            : 0
                        }}
                      </p>
                    </b-card-text>
                  </b-card>
                  <b-card class="txn-benefits-cards your-own-akshaya-patra">
                    <b-card-text class="d-flex akshaya-patra"
                      >Your Own Akshaya Patra:</b-card-text
                    >
                    <p class="akshaya-patra-points">
                      {{
                        transactionBenefits && transactionBenefits[0]
                          ? transactionBenefits[0].value
                          : 0
                      }}
                    </p>
                  </b-card>
                </div>
              </b-collapse>
            </div>
          </template>
          <p class="terms-conditions">Terms & Condition:</p>
          <p class="terms-conditions-text">
            Lorem ipsum, or lipsum as it is sometimes known, is dummy text used
            in laying out print, graphic or web designs.
          </p>
          <button class="paynow-button" @click="gotocart">Pay Now</button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "dth-checkout",
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      productTypeData: (state) => state.billPayment.productTypeData,
      defaultAddress: (state) => state.address.defaultAddress,
      checkoutPriceData: (state) => state.billPayment.checkoutPriceData,
      billData: (state) => state.billPayment.billData.data,
      userInfo: (state) => state.auth.userInfo,
      productData: (state) => state.billPayment.productData,
      categoryitemList: (state) => state.shopDetails.categoryitemList,
      transactionBenefits: (state) => state.billPayment.transactionBenefits,
      finalCheckoutData: (state) => state.billPayment.finalCheckoutData,
      marketList: (state) => state["home"].marketList,
    }),
  },

  methods: {
    async checkoutApi() {
      if (this.$route.params.category === "DTH Payment") {
        this.isLoading = true;
        const selectedCategoryId = this.$route.params.m_id;
        let selectedProductType = [];
        await this.$store.dispatch(
          "billPayment/fetchProductTypeData",
          selectedCategoryId
        );
        if (
          this.productTypeData.map((e) => {
            if (e.isProductType === true) {
              selectedProductType.push(e);
            }
          })
        );
        const payloadData = {
          categoryId: selectedProductType[0]._id,
          offset: 0,
          itemType: "SERVICE",
        };
        // const userInfo = JSON.parse(
        //   localStorage.getItem("eparisheva:userInfo")
        // );
        await this.$store.dispatch("billPayment/fetchProductData", payloadData);
        const payload = {
          item: this.productData[0]._id,
          checkout: {
            type: "COD",
            customerNo: this.userInfo.customerNo,
            userEmail: this.userInfo.authentication.email,
            userPhone: this.userInfo.authentication.phone,
          },
          custom: {
            MobileNumber: this.$route.params.number.toString(),
            PlanCode: "",
            Amount: this.$route.params.amount.toString(),
            OperatorId: this.$route.params.id.toString(),
            CircleId: "0",
            AdParams: {},
            market: {
              name: "Bill Payment",
              serviceProvider: "MOBIKWIK",
            },
            ServiceCategory: "DTH",
            OperatorName: this.$route.params.name.trim(),
          },
          isMobile: false,
        };
        await this.$store.dispatch(
          "billPayment/getCheckoutDataMobile",
          payload
        );
        let data = {
          orderAmount: this.finalCheckoutData.data.totalPrice,
          provider: this.finalCheckoutData.data.items[0].provider,
        };
        await this.$store.dispatch("billPayment/getTransactionBenefits", data);
        this.isLoading = false;
      }
    },
    gotocart() {
      this.checkoutApi();
      this.$router.push({
        name: "payment",
        path: `/payment`,
        params: {
          amount: this.$route.params.amount,
          category: this.$route.params.category,
        },
      });
    },
    goback() {
      this.$router.push({
        name: "dth-pay",
        params: { amount: this.$route.params.amount },
      });
    },
  },
  async mounted() {
    this.checkoutApi();
  },
};
</script>
<style scoped>
.edit {
  margin-left: 80px;
}
.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bill-amount {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 38px;
  color: #000000;
}
.line {
  border: 1px solid #272727;
}
.terms-conditions {
  font-style: normal;
  font-weight: 500;
  margin-top: 15px;
  font-size: 16px;
  line-height: 19px;

  color: #000000;
}
.terms-conditions-text {
  font-style: normal;
  margin-top: 5px;
  font-size: 12px;
  line-height: 19px;
  color: #000000;
}
.enter-amount {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 42px;
  letter-spacing: -0.408px;
  text-transform: capitalize;
  color: #c7c7c7;
}
.amount {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  margin-left: 20px;
  color: #000000;
}
.continue-button {
  box-sizing: border-box;
  width: 370px;
  height: 32px;
  margin-top: 10px;
  color: white;
  background: #272727;
  border-radius: 3px;
}
.total-amount-color {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 38px;
  color: #14982b;
  opacity: 0.99;
}
.total-amount-text {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 38px;
  color: #14982b;
  opacity: 0.99;
}
.text-bill {
  margin-left: 100px;

  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  font-size: 16px;
  text-transform: capitalize;
  color: #808080;
}
.container-2 {
  background: #fdfdfd;
}
.bill-amount {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 38px;
  color: #000000;
}
.enter-amount {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 42px;
  letter-spacing: -0.408px;
  text-transform: capitalize;
  color: #c7c7c7;
}
.amount {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  margin-left: 20px;
  color: #000000;
}
.continue-button {
  box-sizing: border-box;
  width: 260px;
  height: 52px;
  margin-left: 50px;
  color: white;
  background: #272727;
  border-radius: 3px;
}
.paynow-button {
  box-sizing: border-box;
  width: 360px;
  height: 42px;
  color: white;
  background: #272727;
  border-radius: 3px;
}
.number {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}
.name {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  text-transform: capitalize;
  color: #808080;
}
.container-2 {
  width: 520px;
  height: 189px;
  left: 94px;
  top: 193px;
  background: #fdfdfd;
}
.text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  color: #808080;
}
.transaction-benefits .benefitsbtn {
  background-color: rgba(0, 0, 0, 0.03) !important;
  padding: 12px 10px;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;

  color: #272727 !important;
}
.color-transaction {
  background: linear-gradient(#ff1934, #ff4d35, #ff7f37);
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

.showbenefits-div {
  padding: 12px 12px;
  border: 1px solid #ddd;
  border-radius: 0px 0px 10px 10px;
}

.showbenefits-div ul {
  border-bottom: 1px solid #ddd;
}

.invert-color {
  filter: invert(1);
}

.transaction-benefits-heading {
  font-size: 18px;
  font-weight: 500;
}

.address-details {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 11px;
  text-transform: capitalize;
  color: #9e9e9e;
}

.text-danger {
  color: #fe7b08 !important;
}

.price-header {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.card-custom-class {
  border: none;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-bottom-color: rgba(0, 0, 0, 0.125);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  border-radius: 0.25rem;
}

.price-details {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

.price-total-amount {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 19px;
  color: #14982b;
}

.btn-outline-primary {
  width: 180px;
  height: 45px;
}

.btn-primary {
  width: 180px;
  height: 45px;
  border-radius: 3px;
}

.btn-outline-primary:hover {
  width: 180px;
  height: 45px;
}

.txn-benefits-cards {
  border-radius: 8px;
}

.your-cashback {
  background-image: linear-gradient(to right, #fc4f31, #ff7632);
  color: #ffff;
}

.referrer-cashback {
  background-image: linear-gradient(to right, #f5669c, #e44594);
  color: #ffff;
}

.your-own-akshaya-patra {
  background-image: linear-gradient(to right, #2a4ea7, #147f4b);
  color: #ffff;
}

.akshaya-patra-heading {
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 0;
  width: 70%;
  margin-right: 2%;
  float: left;
}

.akshaya-patra {
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 0;
  width: 100%;
  float: left;
}
.akshaya-patra-points {
  font-size: 1.6rem;
  margin-left: 20px;
  float: right;
}
</style>
